import React, { useEffect } from 'react';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { INLINES } from '@contentful/rich-text-types';
import Aos from 'aos';
import 'aos/dist/aos.css';

const ContentBlockSection = ({ sectionTitle, contentBlock }) => {
  // Aos animation settings
  useEffect(() => {
    Aos.init({ duration: 1500, offset: 60 });
  }, []);

  const options = {
    renderNode: {
      [INLINES.ENTRY_HYPERLINK]: (node, children) => {
        return (
          <div>
            {node && node.data && node.data.target && node.data.target.slug &&
              <a href={node.data.target.slug}>{node.content[0].value}</a>
            }
          </div>
        )
      }
    }
  }

  return (
    <section className="content-block-section mb-16 lg:mb-24 px-4 sm:px-6 md:px-12 lg:px-20" data-aos="fade-up">
      <div className="mx-auto max-w-xxl border border-brightTurquoiseApprox rounded-3xl">
        {sectionTitle &&
          <header className="p-6 sm:px-12 sm:py-8 xl:px-24">
            <h2 className="heading-2 text-brightTurquoiseApprox">{sectionTitle}</h2>
          </header>
        }

        <div className="content-block-section__blocks border-t border-brightTurquoiseApprox">
          {contentBlock.map((item, index) => {
            const text = renderRichText(item.content, options);
            return (
              <div className="content-block-section__item p-6 sm:p-12 sm:py-8 xl:px-24 xl:py-14 border-b border-brightTurquoiseApprox">
                <div className="mb-8">
                  {item.blockPretitle &&
                    <p className="heading-6-thin text-brightTurquoiseApprox uppercase">{item.blockPretitle}</p>
                  }
                  {item.blockTitle &&
                    <h3 className="heading-6 uppercase text-brightTurquoiseApprox">{item.blockTitle}</h3>
                  }
                </div>

                {text &&
                  <div className="rich-text">{text}</div>
                }
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}

export default ContentBlockSection;