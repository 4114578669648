import React, { useEffect } from 'react';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { INLINES } from '@contentful/rich-text-types';
import Aos from 'aos';
import 'aos/dist/aos.css';

const TextSectionWithColumns = ({ sectionTitle, sectionIngress, textBlocks }) => {

  // Aos animation settings
  useEffect(() => {
    Aos.init({ duration: 1500, offset: 60 });
  }, []);

  const options = {
    renderNode: {
      [INLINES.ENTRY_HYPERLINK]: (node, children) => {
        return (
          <a href={node.data.target.slug}>{node.content[0].value}</a>
        )
      }
    }
  }

  return (
    <section className="px-4 sm:px-6 md:px-12 lg:px-20" data-aos="fade-up">
      <div className="max-w-xxl mx-auto mb-24">
        <header className="mb-12 md:mb-16">
          {sectionTitle &&
            <h3 className="mb-4 heading-2 text-canary">{sectionTitle}</h3>
          }
          {sectionIngress &&
            <p className="ingress">{sectionIngress.sectionIngress}</p>
          }
        </header>

        <div className="mb-24 grid xs:grid-cols-2 xl:grid-cols-4 gap-6">
          {textBlocks.map((block, index) => {
            const text = renderRichText(block.blockContent, options);

            return (
              <div>
                {text &&
                  <div className="rich-text">{text}</div>
                }
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}

export default TextSectionWithColumns;