import React, { useState, useEffect } from 'react';
import { checkValidity } from '../../shared/utility';
import Spinner from '../spinner/spinner';
import Aos from 'aos';
import 'aos/dist/aos.css';

const ContactSection = ({ sectionTitle, firstName, lastName, email, phone, message, texts }) => {
  // Aos animation settings
  useEffect(() => {
    Aos.init({ duration: 1500, offset: 60 });
  }, []);

  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const [formValues, setFormValues] = useState({
    firstname: '',
    lastname: '',
    email: '',
    phone: '',
    message: ''
  });

  const [invalidMessages, setInvalidMessages] = useState({
    invalidFirstname: '',
    invalidLastname: '',
    invalidEmail: '',
    invalidPhone: '',
    invalidMessage: ''
  });

  function checkValidityElements() {
    if (!checkValidity(formValues.firstname, { required: true })) {
      setInvalidMessages({ ...invalidMessages, invalidFirstname: texts.requiredFirstName });
    } else {
      setInvalidMessages({ ...invalidMessages, invalidFirstname: '' });
    }

    if (!checkValidity(formValues.lastname, { required: true })) {
      setInvalidMessages({ ...invalidMessages, invalidLastname: texts.requiredLastName });
    } else {
      setInvalidMessages({ ...invalidMessages, invalidLastname: '' });
    }

    if (!checkValidity(formValues.email, { required: true, isEmail: true })) {
      setInvalidMessages({ ...invalidMessages, invalidEmail: texts.invalidEmail });
    } else if (formValues.email.includes("@gmail.com") ||
      formValues.email.includes("@hotmail.com") ||
      formValues.email.includes("@live.com") ||
      formValues.email.includes("@yahoo.com") ||
      formValues.email.includes("@outlook.com")) {
      setInvalidMessages({ ...invalidMessages, invalidEmail: texts.excludedEmail });
    } else {
      setInvalidMessages({ ...invalidMessages, invalidEmail: '' });
    }

    if (!checkValidity(formValues.phone, { required: true })) {
      setInvalidMessages({ ...invalidMessages, invalidPhone: texts.requiredPhone });
    } else {
      setInvalidMessages({ ...invalidMessages, invalidPhone: '' });
    }

    return !(invalidMessages.invalidFirstname ||
      invalidMessages.invalidLastname ||
      invalidMessages.invalidEmail ||
      invalidMessages.invalidPhone ||
      invalidMessages.invalidMessage);
  }

  function handleInputChange(e) {
    e.persist();
    setErrorMessage('');
    setFormValues(currentValues => ({
      ...currentValues,
      [e.target.name]: e.target.value
    }));

    switch (e.target.name) {
      case 'firstname':
        if (!checkValidity(e.target.value, { required: true })) {
          setInvalidMessages({ ...invalidMessages, invalidFirstname: texts.requiredFirstName });
        } else {
          setInvalidMessages({ ...invalidMessages, invalidFirstname: '' });
        }
        break;
      case 'lastname':
        if (!checkValidity(e.target.value, { required: true })) {
          setInvalidMessages({ ...invalidMessages, invalidLastname: texts.requiredLastName });
        } else {
          setInvalidMessages({ ...invalidMessages, invalidLastname: '' });
        }
        break;
      case 'email':
        if (!checkValidity(e.target.value, { required: true, isEmail: true })) {
          setInvalidMessages({ ...invalidMessages, invalidEmail: texts.invalidEmail });
        } else if (e.target.value.includes("@gmail.com") ||
          e.target.value.includes("@hotmail.com") ||
          e.target.value.includes("@live.com") ||
          e.target.value.includes("@yahoo.com") ||
          e.target.value.includes("@outlook.com")) {
          setInvalidMessages({ ...invalidMessages, invalidEmail: texts.excludedEmail });
        } else {
          setInvalidMessages({ ...invalidMessages, invalidEmail: '' });
        }
        break;
      case 'phone':
        if (!checkValidity(e.target.value, { required: true })) {
          setInvalidMessages({ ...invalidMessages, invalidPhone: texts.requiredPhone });
        } else {
          setInvalidMessages({ ...invalidMessages, invalidPhone: '' });
        }
        break;
      default:
        break;
    }
  }

  const handleSubmitContact = async event => {
    event.preventDefault();
    const valid = checkValidityElements();
    if (!!valid) {
      setLoading(true);
      const data = new FormData(event.target)
      const success = await fetch("https://edita660.activehosted.com/proc.php", {
        method: "POST",
        body: data,
        mode: "no-cors",
      });

      if (!!success) {
        if (typeof window !== 'undefined') {
          // form submission, dataLayer spec
          // when the form is successfully submitted
          const formName = texts.send.toLowerCase() === 'send' ? 'Contact form' : 'Yhteydenottolomake'
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            'event' : 'formSubmit',
            'formName' : formName, // esim. 'Send Message', 'Lähetä viesti' tai 'Yhteydenottolomake', 'Contact form'
          });
        }
        setErrorMessage('');
        setSuccessMessage(texts.thankyouMessage);
        formValues.firstname = "";
        formValues.lastname = "";
        formValues.email = "";
        formValues.phone = "";
        formValues.message = "";
      } else {
        setErrorMessage(texts.existingContact);
        setSuccessMessage('');
      }
      setLoading(false);
    }
  }

  return (
    <section className="mb-24 px-4 sm:px-6 md:px-12 lg:px-20" data-aos="fade-up">
      <div className="mx-auto max-w-xxl">
        {sectionTitle &&
          <header className="mb-12">
            <h2 className="heading-2 text-canary">{sectionTitle}</h2>
          </header>
        }

        <div className="flex flex-wrap md:-mx-5 mb-4">
          <div className="w-full md:px-5 text-center">
            {!!errorMessage &&
              <p>{errorMessage}</p>
            }
            {!!successMessage &&
              <p>{successMessage}</p>
            }
          </div>
          {loading && <Spinner />}
        </div>
        {(!successMessage && !loading) &&
          <form onSubmit={e => handleSubmitContact(e)} id="contact-form" className="medium-title-book text-brightTurquoiseApprox">
            <input type="hidden" name="u" value="7" />
            <input type="hidden" name="f" value="7" />
            <input type="hidden" name="s" />
            <input type="hidden" name="c" value="0" />
            <input type="hidden" name="m" value="0" />
            <input type="hidden" name="act" value="sub" />
            <input type="hidden" name="v" value="2" />
            <input type="hidden" name="or" value="72a11d29e47553a3facd3c0ebb8eff08" />
            <div className="flex flex-wrap md:-mx-5 mb-12">
              <div className="w-full md:w-1/2 md:px-5 mb-4 md:mb-0">
                <label className="sr-only" htmlFor="first-name">{firstName}</label>
                <input onChange={handleInputChange} value={formValues.firstname} id="firstname" name="firstname" type="text" className="form-input py-2 w-full bg-transparent border-b border-brightTurquoiseApprox focus:outline-none placeholder-brightTurquoiseApprox" placeholder={firstName + "*"} required />
                {!!invalidMessages.invalidFirstname && <p className="text-violetRed mt-2">{invalidMessages.invalidFirstname}</p>}
              </div>
              <div className="w-full md:w-1/2 md:px-5">
                <label className="sr-only" htmlFor="last-name">{lastName}</label>
                <input onChange={handleInputChange} value={formValues.lastname} id="lastname" name="lastname" type="text" className="form-input py-2 w-full bg-transparent border-b border-brightTurquoiseApprox focus:outline-none placeholder-brightTurquoiseApprox" placeholder={lastName + "*"} required />
                {!!invalidMessages.invalidLastname && <p className="text-violetRed mt-2">{invalidMessages.invalidLastname}</p>}
              </div>
            </div>
            <div className="flex flex-wrap md:-mx-5 mb-12">
              <div className="w-full md:w-1/2 md:px-5 mb-4 md:mb-0">
                <label className="sr-only" htmlFor="email">{email}</label>
                <input onChange={handleInputChange} value={formValues.email} id="email" name="email" type="email" className="form-input py-2 w-full bg-transparent border-b border-brightTurquoiseApprox focus:outline-none placeholder-brightTurquoiseApprox" placeholder={email + "*"} required />
                {!!invalidMessages.invalidEmail && <p className="text-violetRed mt-2">{invalidMessages.invalidEmail}</p>}
              </div>
              <div className="w-full md:w-1/2 md:px-5">
                <label className="sr-only" htmlFor="last-name">{phone}</label>
                <input onChange={handleInputChange} value={formValues.phone} id="phone" name="phone" type="text" className="form-input py-2 w-full bg-transparent border-b border-brightTurquoiseApprox focus:outline-none placeholder-brightTurquoiseApprox" placeholder={phone + "*"} required />
                {!!invalidMessages.invalidPhone && <p className="text-violetRed mt-2">{invalidMessages.invalidPhone}</p>}
              </div>
            </div>
            <div className="flex flex-wrap md:-mx-5 mb-4">
              <div className="w-full md:px-5">
                <label className="mb-2 block" htmlFor="message">{message}</label>
                <textarea id="field[7]" name="field[7]" rows="6" className="form-textarea p-3 w-full rounded-xl bg-transparent border border-brightTurquoiseApprox focus:outline-none placeholder-brightTurquoiseApprox" required></textarea>
                {!!invalidMessages.invalidMessage && <p className="text-violetRed mt-2">{invalidMessages.invalidMessage}</p>}
              </div>
            </div>
            <div className="mt-6 w-full px-5 text-center">
              <button type="submit" className="btn btn--turquoise">{texts.send}</button>
            </div>
          </form>
        }
      </div>
    </section>
  )
}

export default ContactSection;