import React, { useEffect } from 'react';
import Aos from 'aos';
import 'aos/dist/aos.css';

const ValuesSection = ({ sectionTitle, values }) => {
  // Aos animation settings
  useEffect(() => {
    Aos.init({ duration: 1500, offset: 60 });
  }, []);

  return (
    <section className="mb-7 lg:mb-24 xl:mb-36 px-4 sm:px-6 md:px-12 lg:px-20" data-aos="fade-up">
      <div className="max-w-xxl mx-auto" style={{paddingTop: 80}}>
        {sectionTitle &&
          <header className="mb-12 md:mb-16">
            <h2 className="heading-2 text-canary">{sectionTitle}</h2>
          </header>
        }

        <div className="max-w-xxl mx-auto">
          <div className="flex flex-wrap">
            {values?.map((value, index) => {
              return (
                <div className="mb-9 px-6 w-full xxs:w-1/2 md:w-1/4">
                  <figure className="mb-2">
                    <img className="mx-auto max-w-xxxs lg:max-w-xxs" src={value.image.file.url} />
                  </figure>
                  <h4 className="heading-6-medium text-brightTurquoiseApprox text-center">{value.title}</h4>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </section>
  );
}

export default ValuesSection;