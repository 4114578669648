import React, { useEffect } from 'react';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import Aos from 'aos';
import 'aos/dist/aos.css';

const CaseSection = ({ sectionTitle, title, client, serviceCategory, richText, nodeLocale, anchorLink, key }) => {
  const text = renderRichText(richText);

  // Aos animation settings
  useEffect(() => {
    Aos.init({ duration: 1500, offset: 60 });
  }, []);

  // Translations for static texts
  const categoryTitle = nodeLocale === 'en-US' ? 'CLIENT:' : 'ASIAKAS:';
  const serviceCategoryTitle = nodeLocale === 'en-US' ? 'SERVICE CATEGORY:' : 'PALVELUKATEGORIA:';

  return (
    <section className={`case-section-bg mb-24 px-4 sm:px-6 md:px-12 lg:px-20 py-24 case-${anchorLink}`} data-aos="fade-up">
      <div className="mx-auto max-w-xxl">
        <header className="mb-12">
          <h2 className="heading-2 text-brightTurquoiseApprox">{sectionTitle}</h2>
        </header>


        <div className="border border-brightTurquoiseApprox rounded-3xl" id={anchorLink}>
          <div className="flex lg-max:flex-col lg:items-center lg:divide-x">
            <div className="flex-shrink-0 p-6 pb-0 sm:p-12 sm:pb-0 lg:p-8 heading-2-book text-brightTurquoiseApprox">
              <h4>{title}</h4>
            </div>

            <div className="flex-auto p-6 sm:p-12 sm:pt-6 lg:p-8 text-brightTurquoiseApprox">
              <div>
                <span className="category-title-bold inline-block">{categoryTitle}</span> <span className="inline-block category-title">{client}</span>
              </div>
              <div>
                <span className="category-title-bold">{serviceCategoryTitle}</span> <span className="category-title">{serviceCategory}</span>
              </div>
            </div>
          </div>

          {text &&
            <div className="case-section p-6 sm:p-12 lg:p-24 border-t border-brightTurquoiseApprox">
              {text}
            </div>
          }
        </div>
      </div>
    </section>
  )
}

export default CaseSection;