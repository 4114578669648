import React, { useEffect } from 'react';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import Aos from 'aos';
import 'aos/dist/aos.css';

const ProcessSection = ({ sectionTitle, sectionDescription, phases }) => {
  // Aos animation settings
  useEffect(() => {
    Aos.init({ duration: 1500, offset: 60 });
  }, []);

  return (
    <section className="px-4 sm:px-6 md:px-12 lg:px-20" data-aos="fade-up">
      <div className="max-w-xxl mx-auto mb-24">
        {sectionTitle &&
          <header className="mb-12">
            <h3 className="mb-6 heading-5 text-canary">{sectionTitle}</h3>
            {sectionDescription &&
              <p className="ingress text-alabaster">{sectionDescription.sectionDescription}</p>
            }
          </header>
        }

        <div>
          {phases?.map((phase, index) => {
            const text = renderRichText(phase.text);

            return (
              <div className="process-card mb-10 flex flex-wrap border border-brightTurquoiseApprox rounded-3xl sm:divide-x divide-brightTurquoiseApprox">
                <figure className="p-6 md:p-12 w-full sm:w-3/12 sm:self-center">
                  <img className="mx-auto" src={phase.image.file.url} alt=""></img>
                </figure>

                {text &&
                  <div className="p-6 md:p-12 w-full sm:w-9/12">
                    {text}
                  </div>
                }
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}

export default ProcessSection;