import React, { useEffect } from 'react';
import Aos from 'aos';
import 'aos/dist/aos.css';

const ListSection = ({ sectionTitle, listItems }) => {
  // Aos animation settings
  useEffect(() => {
    Aos.init({ duration: 1500, offset: 60 });
  }, []);

  return (
    <section className="mb-24 px-4 sm:px-6 md:px-12 lg:px-20" data-aos="fade-up">
      <div className="mx-auto max-w-xxl border border-brightTurquoiseApprox rounded-3xl">
        <header className="p-4 lg:p-8 mx-auto text-center border-b border-brightTurquoiseApprox">
          <h2 className="heading-2 text-brightTurquoiseApprox">{sectionTitle}</h2>
        </header>

        <div className="pt-6 px-6 sm:pt-12 sm:pb-4 sm:px-12 xl:px-18 xl:pb-10 xl:pt-18 flex flex-wrap">
          {listItems.map((item, index) => {
            return (
              <div class="mb-6 sm:mb-8 px-6 w-full md:w-1/2">
                <h3 className="mb-2 heading-6 uppercase text-brightTurquoiseApprox">{item.key}</h3>
                <p className="body-text">{item.value}</p>
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}

export default ListSection;