import React, { useEffect } from 'react';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import Aos from 'aos';
import 'aos/dist/aos.css';

const TextBoxSection = ({ sectionTitle, textBlocks, showContentInColumns }) => {
  // Aos animation settings
  useEffect(() => {
    Aos.init({ duration: 1500, offset: 60 });
  }, []);

  // const text = renderRichText(richText);

  return (
    <section className="text-box-section mb-16 lg:mb-24 px-4 sm:px-6 md:px-12 lg:px-20" data-aos="fade-up">
      <div className="mx-auto max-w-xxl">
        {sectionTitle &&
          <header className="mb-12">
            <h2 className="heading-2 text-canary">{sectionTitle}</h2>
          </header>
        }

        <div className={`border border-brightTurquoiseApprox rounded-3xl ${showContentInColumns ? 'sm-max:p-6 pb-0 sm:grid grid-cols-2' : 'p-6 sm:p-12 lg:px-24 lg:py-16'}`}>
          {textBlocks?.map((item, index) => {
            const text = renderRichText(item.blockContent);

            if (showContentInColumns) {
              return (
                <div key={item.id} className={`sm-max:mb-6 sm:p-12 rich-text border-brightTurquoiseApprox ${index > 1 ? 'border-t' : ''} ${index % 2 === 0 ? '' : 'sm:border-l'}`}>
                  {text}
                </div>
              )
            } else {
              return (
                <div key={item.id} className="rich-text">
                  {text}
                </div>
              )
            }
          })}
        </div>
      </div>
    </section>
  )
}

export default TextBoxSection;