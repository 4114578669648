import React, { useEffect } from 'react';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import Aos from 'aos';
import 'aos/dist/aos.css';

const TextBanner = ({ sectionTitle, richText }) => {
  // Aos animation settings
  useEffect(() => {
    Aos.init({ duration: 1500, offset: 60 });
  }, []);

  const text = renderRichText(richText);

  return (
    <section className="mb-24 px-4 sm:px-20 md:px-24 lg:px-28" data-aos="fade-up">
      <div className="max-w-xxl mx-auto">
        <div className="border border-brightTurquoiseApprox rounded-3xl">
          <header className="p-6 sm:p-12 border-b border-brightTurquoiseApprox">
            <h2 className="heading-3 text-brightTurquoiseApprox">{sectionTitle}</h2>
          </header>

          {text &&
            <div className="rich-text p-6 sm:p-12">
              {text}
            </div>
          }
        </div>
      </div>
    </section>
  )
}
export default TextBanner;
