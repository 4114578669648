import React, { useEffect } from 'react';
import Aos from 'aos';
import 'aos/dist/aos.css';

const GraphSection = ({ sectionTitle, sectionDescription, graph }) => {

  // Aos animation settings
  useEffect(() => {
    Aos.init({ duration: 1500, offset: 60 });
  }, []);

  return (
    <section className="mb-16 lg:mb-24 px-4 sm:px-6 md:px-12 lg:px-20" data-aos="fade-up">
      <div className="mx-auto max-w-xxl">
        <header>
          {sectionTitle &&
            <h2 className="mb-9 heading-2 text-canary">{sectionTitle}</h2>
          }
          {sectionDescription &&
            <p className="heading-6-medium">{sectionDescription.sectionDescription}</p>
          }
        </header>


        {graph &&
          <div className="mt-6">
            <img src={graph} alt="" />
          </div>
        }
      </div>
    </section>
  )

}

export default GraphSection;