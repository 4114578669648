import React, { useEffect } from 'react';
import Aos from 'aos';
import 'aos/dist/aos.css';

const GroupStructure = ({ title, textField, logo, logoMobile, companies }) => {

  // Aos animation settings
  useEffect(() => {
    Aos.init({ duration: 1500, offset: 60 });
  }, []);

  return (
    <section className="mb-24 px-4 sm:px-6 md:px-12 lg:px-20" data-aos="fade-up">
      <div className="max-w-xxl mx-auto">
        <header className="mb-24">
          {title &&
            <h2 className="mb-6 heading-2 text-canary">{title}</h2>
          }
          {/*textField && 
            <p>{textField.textField}</p>
          */}
        </header>

        {logo &&
          <div className="flex hidden lg:flex justify-center">
            <img src={logo} alt="" className="w-10/12" />
          </div>
        }

        {logoMobile &&
          <div className="mb-12 flex lg:hidden justify-center">
            <img src={logoMobile} alt="" className="w-4/12" />
          </div>
        }

        <div className="sm:-mx-5 flex sm-max:flex-col flex-wrap justify-center">
          {companies?.map((company, index) => {
            return (
              <div className="mx-auto lg-max:mb-10 sm:px-5 w-full lg-max:max-w-xs lg:w-1/3">
                <div className="p-6 rounded-3xl border-2 border-brightTurquoiseApprox text-center h-full">
                  <h4 className="mb-1 group-structure-title text-brightTurquoiseApprox">{company.key}</h4>
                  <p className="text-alabaster">{company.value}</p>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )

}

export default GroupStructure;