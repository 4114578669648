import React, { useEffect } from 'react';
import { Link } from 'gatsby';
import { RichText } from '../richText/richText';
import Aos from 'aos';
import 'aos/dist/aos.css';

const ServiceSection = ({ title, serviceAreas, buttonLabel, buttonLink, nodeLocale }) => {
  // Aos animation settings
  useEffect(() => {
    Aos.init({ duration: 1500, offset: 60 });
  }, []);

  const readMoreAboutOurServices = () => {
    if (typeof window !== 'undefined') {
      // when the button is clicked
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
      'event' : 'readAboutServices'
      });
    }
  }

  return (
    <section className="service-section max-w-screen-xl mx-auto mb-16 lg:mb-24 px-4 sm:px-6 md:px-12 lg:px-20" data-aos="fade-up">
      <div className="border border-brightTurquoiseApprox rounded-3xl">
        {/*
        <header className="py-4 lg:py-8 mx-auto max-w-md text-center">
          <h2 className="heading-2 text-brightTurquoiseApprox">{title}</h2>
        </header>
        */}

        <div className="border-brightTurquoiseApprox md:grid md:grid-flow-col">
          {serviceAreas.map((serviceArea, index) => {
              return (
                <div className={`p-6 sm:p-12 sm:pt-9 xl:px-18 xl:pt-14 xl:pb-14`} key={index}>
                  <div>
                    <h3 className="mb-6 heading-6 uppercase text-brightTurquoiseApprox">{serviceArea.title}</h3>
                    <RichText nodeLocale={nodeLocale} raw={serviceArea.text} />
                  </div>
                </div>
              )
          })}

        </div>
      </div>

      <div className="mt-12 text-center">
        <Link className="btn btn--turquoise" to={buttonLink} onClick={() => readMoreAboutOurServices()}>
          {buttonLabel}
        </Link >
      </div>
    </section>
  )

}

export default ServiceSection;