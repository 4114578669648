import React, { useEffect } from 'react';
import { RichText } from '../richText/richText';
import Aos from 'aos';
import 'aos/dist/aos.css';

const InfromationSection = ({ sectionTitle, informationCards, nodeLocale }) => {
  // Aos animation settings
  useEffect(() => {
    Aos.init({ duration: 1500, offset: 60 });
  }, []);

  return (
    <section className="mb-10 lg:mb-18 px-4 sm:px-6 md:px-12 lg:px-20" data-aos="fade-up">
      <div className="max-w-xxl mx-auto">
        <header className="mb-9">
          {sectionTitle &&
            <h3 className="mb-4 heading-2 text-canary">{sectionTitle}</h3>
          }
        </header>

        <div className="-mx-6 flex flex-wrap">
          {informationCards.map((card) => {
            return (
              <div key={card.id} className="information-card px-6 w-full sm:w-1/2">
                {card.content &&
                  <RichText nodeLocale={nodeLocale} raw={card.content} />
                }
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}

export default InfromationSection;