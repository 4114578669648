import React, { useEffect } from 'react';
// import CountUp, { useCountUp } from 'react-countup';
// import VisibilitySensor from "react-visibility-sensor";
import Aos from 'aos';
import 'aos/dist/aos.css';

const HighlightsSection = ({ highlights }) => {

  // Aos animation settings
  useEffect(() => {
    Aos.init({ duration: 1500, offset: 60, once: true });
  }, []);


  return (
    <section className="mb-24 px-4 sm:px-6 md:px-12 lg:px-20 mb-16 lg:mb-24" data-aos="fade-up">
      <div className="max-w-screen-lg mx-auto">
        <div className="md:-mx-6 lg:-mx-12 md:flex flex-wrap justify-center items-center">
          {highlights.map((item, index) => {
            let delay = index * 250;

            if (index == '6') {
              return (
                <div className="mb-12 md:px-6 lg:px-12 md-max:text-center" key={index} data-aos="fade-up" data-aos-delay={delay}>
                  <h3 className="mb-2 mega-title text-brightTurquoiseApprox">
                    {item.value}
                  </h3>
                  <p className="highlights-title text-alabaster uppercase">{item.key}</p>
                </div>
              )
            } else {
              return (
                <div className="mb-12 md:px-6 lg:px-12 flex-1 md-max:text-center" key={index} data-aos="fade-up" data-aos-delay={delay}>
                  <h3 className="mb-2 mega-title text-brightTurquoiseApprox">
                    {item.value}
                  </h3>
                  <p className="highlights-title text-alabaster uppercase">{item.key}</p>
                </div>
              )
            }
          })}
        </div>
      </div>
    </section >
  )

}

export default HighlightsSection;