import React, { useEffect } from 'react';
import PersonCard from '../cards/personCard';
import Aos from 'aos';
import 'aos/dist/aos.css';

const PeopleSection = ({ title, people, columnCount }) => {
  // Aos animation settings
  useEffect(() => {
    Aos.init({ duration: 1500, offset: 60 });
  }, []);

  return (
    <section className="mb-12 lg:mb-20 px-4 sm:px-6 md:px-12 lg:px-20" data-aos="fade-up">
      <div className="max-w-xxl mx-auto" style={{paddingTop: 80}}>
        <header>
          <h2 className="mb-9 heading-2 text-canary">{title}</h2>
        </header>

        <div className={`grid xxs:grid-cols-2 sm:grid-cols-3  xl-max:gap-x-6 xl:gap-x-10 ${columnCount == '5' ? 'lg:grid-cols-5' : 'lg:grid-cols-4'}`}>
          {people.map((person, index) => {
            if (columnCount == '4') {
              return (
                <PersonCard
                  key={index}
                  backgroundColor={person.imageBackgroundColor}
                  image={person.image.gatsbyImageData}
                  name={person.name}
                  position={person.position}
                  telephoneNumber={person.telephoneNumber}
                  email={person.email}
                />
              )
            } else {
              return (
                <PersonCard
                  key={index}
                  backgroundColor='Blue'
                  image={person.image.gatsbyImageData}
                  name={person.name}
                  position={person.position}
                  telephoneNumber={person.telephoneNumber}
                  email={person.email}
                  columnCount={columnCount}
                />
              )
            }
          })}
        </div>
      </div>
    </section >
  )
}

export default PeopleSection;
