import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';

const PersonCard = ({ backgroundColor, image, name, position, telephoneNumber, email, columnCount }) => {

  // Background color
  let color = '';

  if (backgroundColor === 'Pink') {
    color = 'bg-violetRed';
  } else if (backgroundColor === 'Green') {
    color = 'bg-springGreen';
  } else if (backgroundColor === 'Blue') {
    color = 'bg-anakiwaApprox';
  } else if (backgroundColor === 'Violet') {
    color = 'bg-heliotrope';
  } else if (backgroundColor === 'White') {
    color = 'bg-white';
  } else if (backgroundColor === 'Yellow') {
    color = 'bg-canary';
  } else if (backgroundColor === 'Orange') {
    color = 'bg-persimon';
  } else if (backgroundColor === 'Turquoise') {
    color = 'bg-brightTurquoiseApprox';
  } else {
    color = 'bg-violetRed';
  };

  let titleSize = '';
  let fontSize = '';

  if (columnCount == '5') {
    fontSize = 'tiny-title';
    titleSize = 'small-title small-title--semibold uppercase'
  } else {
    fontSize = 'tiny-title';
    titleSize = 'medium-title';
  }

  let strippedTelephoneNumber = "";
  
  if (telephoneNumber) {
    strippedTelephoneNumber = telephoneNumber.replace(/\s+/g, '')
  }

  return (
    <div className="mb-10 text-center text-white">
      <figure className={`mb-6 ar-box ar-box-1-1 relative z-10 rounded-3xl overflow-hidden xs-max:max-w-xxs xs-max:mx-auto ${color}`}>
        <GatsbyImage
          image={image}
          style={{ position: 'absolute', top: '0', left: '0', width: '100%', zIndex: '2', height: '100%' }}
          imgStyle={{ objectFit: 'cover', objectPosition: '0% 6px' }}
        />
      </figure>

      <h5 className={`${titleSize} mb-3`}>{name}</h5>
      <div className={`mb-1 ${fontSize}`}>{position}</div>
      <a className={`${fontSize} mb-1 block`} href={`tel:${strippedTelephoneNumber}`}>{telephoneNumber}</a>
      <a className={`${fontSize} break-words block`} href={`mailto:${email}`}>{email}</a>
    </div >
  );
}

export default PersonCard;