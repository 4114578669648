import React, { useEffect } from 'react';
import Reference from '../references/reference';
import Aos from 'aos';
import 'aos/dist/aos.css';

const ReferenceSection = ({ sectionTitle, logos }) => {

  // Aos animation settings
  useEffect(() => {
    Aos.init({ duration: 1500, offset: 60 });
  }, []);

  // Divide logos to subarrays for to be displayed in the referencesections
  const chunkSize = logos.length / 5;
  const modifiedLogosArray = [...Array(Math.ceil(logos.length / chunkSize))].map((_, i) => logos.slice(i * chunkSize, i * chunkSize + chunkSize));

  // Delay referencesections so they animate on different intervals
  const delay = [];
  modifiedLogosArray.forEach((item, index) => delay.push(index));

  const shuffleArray = array => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      const temp = array[i];
      array[i] = array[j];
      array[j] = temp;
    }
  }

  shuffleArray(delay);

  return (
    <section className="text-section mb-16 lg:mb-24 px-4 sm:px-6 md:px-12 lg:px-20" data-aos="fade-up">
      <div className="mx-auto max-w-xxl">
        {sectionTitle &&
          <header className="mb-12">
            <h2 className="heading-2 text-violetRed">{sectionTitle}</h2>
          </header>
        }

        <div className="lg:-mx-12 flex flex-wrap">
          {modifiedLogosArray.map((logoGroup, index) => {
            return (
              <Reference logoGroup={logoGroup} delay={delay[index]} />
            )
          })}
        </div>
      </div>
    </section>
  )
}

export default ReferenceSection;