import React from 'react';
import { INLINES, MARKS } from "@contentful/rich-text-types"
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { GatsbyImage } from "gatsby-plugin-image";

export const RichText = ({ raw, nodeLocale, references }) => {
  let locale = nodeLocale === 'en-US' ? 'en' : 'fi';

  const Bold = ({ children }) => <b>{children}</b>
  const Italic = ({ children }) => <i>{children}</i>

  const options = {
    renderMark: {
      [MARKS.BOLD]: text => <Bold>{text}</Bold>,
      [MARKS.ITALIC]: text => <Italic>{text}</Italic>,
    },
    renderNode: {
      [INLINES.ENTRY_HYPERLINK]: (node, children) => {
        return (
          <a href={`/${locale}/${node.data.target?.slug}`}>{node.content[0].value}</a>
        )
      },
      [INLINES.HYPERLINK]: (node, children) => {
        return (
          <a href={node.data.uri} target="_blank">{node.content[0].value}</a>
        )
      },
      "embedded-asset-block": node => {
        const { gatsbyImageData } = node.data.target
        if (!gatsbyImageData) {
          // asset is not an image
          return null
        }
        return (
          <figure className="mb-6">
            <GatsbyImage image={gatsbyImageData} />
          </figure>
        )
      },
    },
    renderText: text => {
      return text.split('\n').reduce((children, textSegment, index) => {
        return [...children, index > 0 && <br key={index} />, textSegment];
      }, []);
    },
  }

  const text = renderRichText(raw, options);

  return <div className="rich-text">{text}</div>
}