import React, { useEffect } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import Aos from 'aos';
import 'aos/dist/aos.css';

const VideoImageSection = ({ image, video, fullWidth, linkUrl, mediaType }) => {
  // Aos animation settings
  useEffect(() => {
    Aos.init({ duration: 1500, offset: 60 });
  }, []);

  if (!linkUrl) {
    if (mediaType === 'image/jpeg' || mediaType === 'image/png' || mediaType === 'image/webm') {
      if (fullWidth) {
        return (
          <section className="mb-12 lg:mb-24 relative w-full" data-aos="fade-up">
            <a href={linkUrl} target="_blank">
              <div className="ar-box ar-box-2-1 z-10 relative">
                <GatsbyImage
                  image={image}
                  style={{ position: 'absolute', top: '0', left: '0', width: '100%', height: '100%' }}
                  imgStyle={{ objectFit: 'cover', objectPosition: 'center center' }}
                />
              </div>
            </a>
          </section >
        )
      } else {
        return (
          <section className="mb-12 lg:mb-24 mx-auto max-w-xxl" data-aos="fade-up">
            <a href={linkUrl} target="_blank">
              <div className="ar-box ar-box-2-1 z-10 relative">
                <GatsbyImage
                  image={image}
                  style={{ width: '100%', height: '100%' }}
                />
              </div>
            </a>
          </section>
        )
      }
    } else {
      return (
        <section className="mb-12 lg:mb-24 relative w-full" data-aos="fade-up">
          <div className="ar-box ar-box-5-2 z-10 relative">
            {video &&
              <video
                className="absolute top-0 left-0 w-full h-full object-cover"
                autoPlay
                loop
                muted
                playsInline
                webkit-playsInline
                preload="auto">
                <source src={video.file.url} type="video/mp4" />
              </video>
            }
          </div>
        </section>
      )
    }
  } else {
    if (linkUrl.indexOf('youtube.com') !== -1) {
      return (
        <section className="mb-24 px-4 sm:px-6 md:px-12 lg:px-20" data-aos="fade-up" style={{marginTop: '-70px', marginBottom: 0}}>
          <div className="mx-auto max-w-xxl">
            <div style={{align: 'center', position:'relative', paddingBottom: '56.25%', paddingTop: '25px', height: 0}}>
              <iframe
                style={{position:'absolute', top: 0, left: 0, width: '100%', height: '100%'}}
                id="ytplayer"
                src={linkUrl}
                type="text/html"
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture ; fullscreen"
              />
            </div>
          </div>
        </section>
      )
    } else if (linkUrl.indexOf('vimeo.com') !== -1) {
      return (
        <section className="mb-24 px-4 sm:px-6 md:px-12 lg:px-20" data-aos="fade-up" style={{marginTop: '-70px', marginBottom: 0}}>
          <div className="mx-auto max-w-xxl">
            <div style={{align: 'center', position:'relative', paddingBottom: '56.25%', paddingTop: '25px', height: 0}}>
              <iframe
                style={{position:'absolute', top: 0, left: 0, width: '100%', height: '100%'}}
                id="ytplayer"
                src={linkUrl.replace("https://vimeo.com/","https://player.vimeo.com/video/")}
                type="text/html"
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture ; fullscreen"
              />
            </div>
          </div>  
        </section>
      )
    } else {
      if (mediaType === 'image/jpeg' || mediaType === 'image/png' || mediaType === 'image/webm') {
        if (fullWidth) {
          if (linkUrl) {
            return (
              <section className="mb-12 lg:mb-24 relative w-full" data-aos="fade-up">
                <a href={linkUrl} target="_blank">
                  <div className="ar-box ar-box-2-1 z-10 relative">
                    <GatsbyImage
                      image={image}
                      style={{ position: 'absolute', top: '0', left: '0', width: '100%', height: '100%' }}
                      imgStyle={{ objectFit: 'cover', objectPosition: 'center center' }}
                    />
                  </div>
                </a>
              </section >
            )
          } else {
            return (
              <section className="mb-12 lg:mb-24 relative w-full" data-aos="fade-up">
                <div className="ar-box ar-box-2-1 z-10 relative">
                  <GatsbyImage
                    image={image}
                    style={{ position: 'absolute', top: '0', left: '0', width: '100%', height: '100%' }}
                    imgStyle={{ objectFit: 'cover', objectPosition: 'center center' }}
                  />
                </div>
              </section>
            )
          }
        } else {
          if (linkUrl) {
            return (
              <section className="mb-12 lg:mb-24 mx-auto max-w-xxl" data-aos="fade-up">
                <a href={linkUrl} target="_blank">
                  <div className="ar-box ar-box-2-1 z-10 relative">
                    <GatsbyImage
                      image={image}
                      style={{ width: '100%', height: '100%' }}
                    />
                  </div>
                </a>
              </section>
            )
          } else {
            return (
              <section className="mb-12 lg:mb-24 mx-auto max-w-xxl" data-aos="fade-up">
                <div className="ar-box ar-box-2-1 z-10 relative">
                  <GatsbyImage
                    image={image}
                    style={{ width: '100%', height: '100%' }}
                  />
                </div>
              </section>
            )
          }
        }
      } else {
        return (
          <section className="mb-12 lg:mb-24 relative w-full" data-aos="fade-up">
            <div className="ar-box ar-box-5-2 z-10 relative">
              {video &&
                <video
                  className="absolute top-0 left-0 w-full h-full object-cover"
                  autoPlay
                  loop
                  muted
                  playsInline
                  webkit-playsInline
                  preload="auto">
                  <source src={video.file.url} type="video/mp4" />
                </video>
              }
            </div>
          </section>
        )
      }
    }
  }
}

export default VideoImageSection;