import React, { useEffect, useRef, useState } from 'react';
import Aos from 'aos';
import 'aos/dist/aos.css';
import marker from '../../assets/images/marker.svg';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import markerImage from '../../assets/images/small_logo.png';

const Mapbox = ({ token, styleUrl }) => {

  // Aos animation settings
  useEffect(() => {
    Aos.init({ duration: 1500, offset: 60 });
  }, []);

  const mapContainer = useRef(null);
  const [map, setMap] = useState(null);

  const [lng, setLng] = useState(24.9756486);
  const [lat, setLat] = useState(60.1903186);
  const [zoom, setZoom] = useState(16);


  useEffect(() => {

    const map = new mapboxgl.Map({
      container: mapContainer.current,
      accessToken: token,
      style: styleUrl,
      center: [24.9778015, 60.1903924],
      zoom: zoom,
      pitch: 65,
      bearing: 125,
    });

    map.scrollZoom.disable();
    map.scrollZoom.setWheelZoomRate(0.02); // Default 1/450

    map.on("wheel", event => {
      if (event.originalEvent.ctrlKey) { // Check if CTRL key is pressed
        event.originalEvent.preventDefault(); // Prevent chrome/firefox default behavior
        if (!map.scrollZoom._enabled) map.scrollZoom.enable(); // Enable zoom only if it's disabled
      } else {
        if (map.scrollZoom._enabled) map.scrollZoom.disable(); // Disable zoom only if it's enabled
      }
    });

    setMap(map);
    map.on('load', () => {
      map.loadImage(
        markerImage,
        (error, image) => {
          if (error) throw error;

          map.addImage('office', image);

          map.addSource('point', {
            'type': 'geojson',
            'data': {
              'type': 'FeatureCollection',
              'features': [
                {
                  'type': 'Feature',
                  'geometry': {
                    'type': 'Point',
                    'coordinates': [24.9778015, 60.1903924]
                  }
                }
              ]
            }
          });

          map.addLayer({
            'id': 'points',
            'type': 'symbol',
            'source': 'point',
            'layout': {
              'icon-image': 'office',
              'icon-size': 0.25
            }
          });

        }
      );
    });

    return () => { map.remove(); }
  }, []);

  return (
    <section className="mb-24" data-aos="fade-up">

      <div ref={mapContainer} className="map-container" />

    </section>
  )

}

export default Mapbox;