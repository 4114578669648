import React, { useEffect } from 'react';
import Aos from 'aos';
import 'aos/dist/aos.css';
import Lottie from 'react-lottie';
import timeline from '../../assets/animations/timeline.json'
import timeline_fi from '../../assets/animations/timeline_fi.json'

const TimelineSection = ({ sectionTitle, timelineItems, nodeLocale }) => {
  // Aos animation settings
  useEffect(() => {
    Aos.init({ duration: 1500, offset: 60 });
  }, []);

  const anim = nodeLocale === 'en-US' ? timeline : timeline_fi;

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: anim,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <section className="timeline-background px-4 sm:px-6 md:px-12 lg:px-20" data-aos="fade-up">
      <div className="mx-auto max-w-xxl">
        <header>
          <h2 className="heading-2 text-canary">{sectionTitle}</h2>
        </header>

        <div>
          <div class="timeline">

            <Lottie
              options={defaultOptions}
              height='100%'
              width='100%'
            />

          </div>
        </div>
      </div>
    </section>
  )

}

export default TimelineSection;
