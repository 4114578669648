import React, { useState, useEffect } from 'react';
import { Transition } from '@headlessui/react';
import { GatsbyImage } from 'gatsby-plugin-image';

const Reference = ({ logoGroup, delay }) => {

  const [currentLogoNumber, setCurrentLogoNumber] = useState(0);
  const [currentImage, setImage] = useState(logoGroup[0].gatsbyImageData);
  const [referenceShow, setReferenceShow] = useState(true);
  const [wait, setWait] = useState(delay * 450 * 4.5);

  let timeout = null;

  useEffect(() => {
    timeout === null && logoEntered()
  }, []);

  // Fired after init and when logo appear animation has ended
  const logoEntered = () => {
    timeout = setTimeout(() => { setReferenceShow(false) }, 1500 + wait);
    setWait(16500);
  }

  // Fire after logo disapper animation has ended
  const logoLeft = () => {
    const nextLogoNumber = currentLogoNumber < logoGroup.length - 1 ? currentLogoNumber + 1 : 0
    setCurrentLogoNumber(nextLogoNumber)
    setImage(logoGroup[nextLogoNumber].gatsbyImageData)
    timeout = setTimeout(() => { setReferenceShow(true) }, 125);
  }

  return (
    <div className="px-4 lg:px-12 w-1/2 xs:w-1/2 sm:w-1/3 md:w-1/4 lg:w-1/5 flex"
      style={{
        flexDirection: "column",
        justifyContent: "center",
        height: '140px'
      }}>
      <figure>
        <Transition
          show={referenceShow}
          afterEnter={logoEntered}
          afterLeave={logoLeft}
          enter="transform transition ease-out duration-75"
          enterFrom="opacity-0 translate-y-1/2"
          enterTo="opacity-100 translate-y-0"
          leave="transform transition ease-out duration-150"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 -translate-y-1/2"
        >
          <GatsbyImage
            image={currentImage}
          />
        </Transition>
      </figure>
    </div>
  );
}

export default Reference;