import React from 'react';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import Aos from 'aos';
import 'aos/dist/aos.css';

const TextSectionQuote = ({ heading, richText, quote }) => {
  const text = renderRichText(richText);
  const quoteBlock = renderRichText(quote);

  return (
    <section className="text-section mb-16 lg:mb-24 px-4 sm:px-6 md:px-12 lg:px-20" data-aos="fade-up">
      <div className="mx-auto max-w-xxl" style={{paddingTop: 80}}>
        <header>
          {heading &&
            <h2 className="mb-6 heading-2 text-canary">{heading.heading}</h2>
          }
        </header>

        <div className="flex flex-wrap">
          <div className="w-full lg:-mx-5 lg:px-5 lg:w-5/12 lg-max:order-last lg-max:pt-16">
            {quote &&
              <div className="blockquote-section">
                {quoteBlock}
              </div>
            }
          </div>

          <div className="w-full lg:px-5 lg:w-7/12">
            {text &&
              <div>
                {text}
              </div>
            }
          </div>

        </div>
      </div>
    </section>
  )
}

export default TextSectionQuote;